import { gql, useQuery } from '@apollo/client'
import useGlobalContext from '../GlobalContext/useGlobalContext'

export const GetGenericContent = gql`
  query GetGenericContent($slug: String!, $locale: String!) {
    genericContentCollection(where: { slug: $slug }, limit: 1, locale: $locale) {
      items {
        slug
        name
        type
        contents
      }
    }
  }
`

type ContentfulLazyLoadProps = {
  slug: string
  children: (componentData: any) => JSX.Element
}

function ContentfulLazyLoad({ slug, children }: ContentfulLazyLoadProps) {
  const { locale } = useGlobalContext()
  const { data } = useQuery(GetGenericContent, {
    variables: {
      slug,
      locale,
    },
    context: {
      clientName: 'contentful',
    },
    skip: !slug || !children,
  })

  if (!data?.genericContentCollection?.items?.[0]?.contents) return null

  return <>{children(data.genericContentCollection.items[0].contents)}</>
}

export default ContentfulLazyLoad
